import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { UndecoratedLink } from 'common/UndecoratedLink';
import { H1 } from 'common/H1';
import { H4 } from 'common/H4';
import colors from '../constants/colors';

const links = [];

const HeaderContainer = styled.header`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  background: ${colors.GREY_LIGHT};
  border-bottom: 1px solid ${colors.GREY};
`;

const TitleWrapper = styled(H1)`
  font-size: 1.5rem;
  margin-bottom: 0;
  color: ${colors.PINK};
  font-family: 'Satisfy', cursive;
  display: inline-block;
  text-transform: lowercase;
  text-decoration: none;
`;

const SubtitleWrapper = styled(H4)`
  font-size: 1rem;
  color: ${colors.BLACK};
  font-family: Courier, monospace;
  display: inline-block;
  text-transform: lowercase;
  margin-left: 1rem;
`;

const HeaderLink = styled(UndecoratedLink)``;

const NavList = styled.nav``;

const NavItem = styled.div``;

const Header = ({ siteTitle, siteSubTitle }) => (
  <HeaderContainer>
    <HeaderLink to='/'>
      <TitleWrapper>{siteTitle}</TitleWrapper>
      <SubtitleWrapper>{siteSubTitle}</SubtitleWrapper>
    </HeaderLink>
    <NavList>
      {links.map((link) => (
        <HeaderLink to={link.to} key={link.to}>
          <NavItem>{link.label}</NavItem>
        </HeaderLink>
      ))}
    </NavList>
  </HeaderContainer>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
