const PURPLE = '#501F3A';
const PINK = '#CB2D6F';
const GREY = '#CCC';
const GREY_LIGHT = '#EEE';
const TURQUOISE = '#14A098';
const BLACK = '#0F292F';
const WHITE = '#FFF';

export default {
  PURPLE,
  PINK,
  GREY,
  GREY_LIGHT,
  TURQUOISE,
  BLACK,
  WHITE,
};
