import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import { pages } from '../constants/pages';
import breakpoints from '../constants/breakpoints';

const MenuWrapper = styled.nav`
  background-color: ${colors.TURQUOISE};
  display: flex;
  flex-basis: 100%;
  padding: 0.25rem 0;

  @media (min-width: ${breakpoints.SMALL}) {
    padding: 2rem;
    flex-direction: column;
    flex-basis: 25%;
    max-width: 300px;
  }
`;

const MenuLink = styled.a`
  text-decoration: none;
  color: #fff;
  font-family: Courier, monospace;
  padding: 0 0.5rem;

  @media (min-width: ${breakpoints.SMALL}) {
    padding: 0;
  }
`;

const Menu = ({ menuItems = pages }) => (
  <MenuWrapper>
    {Object.keys(menuItems).map((key) => {
      if (menuItems.hasOwnProperty(key)) {
        const element = menuItems[key];
        const attr =
          element.url.indexOf('//') !== -1
            ? { target: '_blank', ...element.attr }
            : element.attr;
        return (
          <MenuLink href={element.url} {...attr}>
            {element.name}
          </MenuLink>
        );
      }
    })}
  </MenuWrapper>
);

export { Menu };
