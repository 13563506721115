import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Header from './header';
import './layout.css';
import { Menu } from './menu';
import breakpoints from '../constants/breakpoints';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding-top: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  line-height: 2;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.SMALL}) {
    flex-wrap: nowrap;
  }
`;

const Main = styled.main`
  margin: 3rem 2rem;
  flex-basis: 75%;
`;

const Layout = ({ children, menuItems }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            subTitle
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          siteSubTitle={data.site.siteMetadata.subTitle}
        />
        <Wrapper>
          <Menu menuItems={menuItems} />
          <Main>{children}</Main>
        </Wrapper>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
