const pages = {
  home: {
    url: '/',
    name: 'Home',
  },
  twitter: {
    url: 'https://twitter.com/kirbeep',
    name: 'Twitter',
  },
};

export { pages };
