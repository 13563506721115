import styled from 'styled-components';
import colors from '../constants/colors';

const H4 = styled.h4`
  color: ${colors.BLACK};
  font-family: Courier, monospace;
  font-weight: 900;
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export { H4 };
